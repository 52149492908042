<template>
  <v-parallax src='../assets/background.png' class="gralContainer">
    <v-container>

      <v-overlay :model-value="this.overlay" class="align-center justify-center">
        <v-progress-circular color="primary" size="64" indeterminate></v-progress-circular>
      </v-overlay>

      <!-- src="../assets/PSI-Logo.png" -->
      <v-row>
        <v-col>
          <v-img class="mx-auto" :width="600" aspect-ratio="1/1" src="../assets/headImg.png"></v-img>
        </v-col>
      </v-row>
      <v-container fluid class="position-relative">
      <!-- Contenido de tu aplicación -->
      <v-row>
        <v-col>
          <!-- Otros componentes -->
        </v-col>
      </v-row>

      <!-- Select en la esquina superior derecha -->
      <v-select style="max-width: 200px; margin-top: -5%;" v-model="selectedLang" label="Language..." :items=langItems item-title="name"
        item-value="id" variant="solo" class="pa-1 top-right-select"></v-select>
      <v-spacer></v-spacer>
    </v-container>

     

      <v-container class="FormContainer">

        <!-- <v-combobox style="max-width: 200px;" label="Language" :items="['Español', 'English']"
          variant="solo-filled"></v-combobox> -->
        <v-dialog v-model="alert" max-width="600" persistent>

          <v-card prepend-icon="mdi-alert-circle" :title=titleText>
            <v-text class="mx-auto">
                {{ this.responseCodeTittle }}
            </v-text>
          </br>
            <v-text class="mx-auto">
                {{ this.responseCode }}
            </v-text>
          </br>
            <v-text class="mx-auto">
                {{ this.responseCodeAds }}
            </v-text>
          </br>
            <template v-slot:actions>
              
              <v-btn @click="closeDialog()">
                {{ this.selectedLang == 1 ? 'Agree' : 'Aceptar' }}
              </v-btn>
            </template>
          </v-card>
        </v-dialog>

       
        <v-row>
        <v-col v-if="this.selectedLang == 1">
          <v-img class="mx-auto" :width="500" aspect-ratio="1/1" src='../assets/signup.png'></v-img>
        </v-col>

        <v-col v-if="this.selectedLang != 1">
          <v-img class="mx-auto" :width="500" aspect-ratio="1/1" src='../assets/registro.png'></v-img>
        </v-col>
      </v-row>
             
        <v-row allign="center" justify="center">
          <v-col class="d-flex justify-center" xs="12" sm="12" md="6" lg="5">
            <v-card class="textForm">
              <v-card-text>

                <v-form ref="form">
                  <v-text-field v-model.trim="name" color :rules="nameRules" counter outlined :maxlength="20"
                    :label="this.selectedLang == 1 ? 'User' : 'Usuario'" required></v-text-field>

                  <v-text-field v-model="email" :rules="emailRules" counter :maxlength="100" label="E-mail" required></v-text-field>

                  <v-text-field v-model.trim="password" type="password" color counter :maxlength="20" :rules="passwordRules" outlined
                    :label="this.selectedLang == 1 ? 'Password' : 'Contraseña'" required></v-text-field>
                  <v-text-field v-model.trim="confirmPassword" type="password" color counter :maxlength="50" :rules="confirmPasswordRules" outlined
                    :label="this.selectedLang == 1 ? 'Confirm Password' : 'Confirma contraseña'"
                    required></v-text-field>
                  
                  <v-text-field v-model.trim="code" color :maxlength="10" counter :rules="codeRules" outlined :placeholder="this.selectedLang == 1 ? 'Optional' : 'Opcional'"
                    :label="this.selectedLang == 1 ? 'Invitation Code (Optional)' : 'Código de invitación (Opcional)'"></v-text-field>

                  <v-checkbox v-model="poliCheck" :rules="poliRules" style="margin-bottom: -25px;">
                    <template v-slot:label>
                      <div>
                        {{ this.selectedLang == 1 ? 'I agree with the' : 'Estoy de acuerdo con la' }}
                        <v-tooltip location="bottom">
                          <template v-slot:activator="{ props }">
                            <span style="color: blue;" @click="poliOpen()">{{ this.selectedLang == 1 ? 'privacy policy' : 'Politica de privacidad' }}</span>
                          </template>
                          Opens in new window
                        </v-tooltip>

                      </div>
                    </template>
                  </v-checkbox>

                  <v-checkbox class="checks" v-model="termsCheck" :rules="termsRules" style="margin-bottom: -25px;">
                    <template v-slot:label>
                      <div>
                        
                        {{ this.selectedLang == 1 ? 'I agree with the' : 'Estoy de acuerdo con los' }}
                        <v-tooltip>
                          <template v-slot:activator="{ props }">
                            <span style="color: blue;" @click="termsOpen()">{{ this.selectedLang == 1 ? 'terms and conditions' : 'terminos y condiciones' }}</span>
                          </template>
                          Opens in new window
                        </v-tooltip>

                      </div>
                    </template>
                  </v-checkbox>

                  <v-checkbox v-model="adsCheck" style="margin-bottom: -25px;">
                    <template v-slot:label>
                      <div>
                        {{ this.selectedLang == 1 ? 'I would like to receive news about Pet Society Island' : 'Me gustaria recibir noticias acerca de Pet Society Island' }}
                        
                      </div>
                    </template>
                  </v-checkbox>

                </v-form>
              </v-card-text>

              <v-card-actions>
                <!-- <v-btn color="deep-purple-accent-4" text="Learn More" variant="text"></v-btn> -->
                <v-row allign="center" justify="center">
                  <v-col style="max-width: 30%;">
                    <v-btn variant="tonal" style="border-radius: 25px;" class="mt-2" block @click="SendData">
                      {{ this.selectedLang == 1 ? 'Sign In' : 'Registrar' }}
                    </v-btn>
                  </v-col>

                  <v-col style="max-width: 30%;">
                    <v-btn variant="tonal" style="border-radius: 25px;" class="mt-2" block @click="clean()">
                      {{ this.selectedLang == 1 ? 'Clear' : 'Limpiar' }}

                    </v-btn>
                  </v-col>

                </v-row>
              </v-card-actions>

            </v-card>
          </v-col>
        </v-row>

      </v-container>

      <template>
        <div class="pa-4 text-center">
          <v-dialog v-model="poliDialog" max-width="800">

            <template v-slot:default="{ isActive }">
              <v-card title="Privacy policy">

                <v-card>
                  <iframe style="width:100%" width="600" height="600" src="http://privacypolicy.petsocietyisland.com/" ></iframe>
                </v-card>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="surface-variant" text="Agree" variant="flat" @click="isActive.value = false"></v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </div>
      </template>


      <template>
        <div class="pa-4 text-center">
          <v-dialog v-model="termsDialog" max-width="800">

            <template v-slot:default="{ isActive }">
              <v-card title="Terms and conditions">

                <v-card>
                  <iframe style="width:100%" width="600" height="600" src="https://termsofuse.petsocietyisland.com/"></iframe>
                </v-card>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="surface-variant" text="Agree" variant="flat" @click="isActive.value = false"></v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </div>
      </template>

      
    </v-container>

  </v-parallax>


</template>

<script>

import axios from 'axios';

export default {
  name: "App",
  data() {
    return {
      alert: false,
      overlay: false,
      responseCode: '',
      responseCodeTittle: '',
      responseCodeAds: '',
      selectedLang: 1,
      langItems: [
        {
          id: 1,
          name: 'English',
        },
        {
          id: 2,
          name: 'Español',
        }
      ],
      code: '',
      titleText: '',
      codeRules: [
        v => (v && v.length == 8 || v.length == 0) || (this.selectedLang == 1 ? 'Code must be 8 characters' : 'Codigo contener tener 8 caracteres'),
      ],
      termsRules: [
      v => !!v || (this.selectedLang == 1 ? 'This field is required' : 'Este campo es requerido'),
      ],
      poliRules: [
      v => !!v || (this.selectedLang == 1 ? 'This field is required' : 'Este campo es requerido'),
      ],
      name: '',
      nameRules: [
  v => !!v || 'User is required', 
  v => (v && v.length > 3) || 
       (this.selectedLang == 1 
         ? 'User must be more than 7 characters' 
         : 'Usuario debe contener 7 o más caracteres'),
  v => /^[a-zA-Z0-9]+$/.test(v) || 
       (this.selectedLang == 1 
         ? 'User must only contain alphabets and numerics without spaces' 
         : 'Usuario no puede contener caracteres especiales, espacios ni guiones'),
],
      password: '',
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length > 7) || (this.selectedLang == 1 ? 'Password must be more than 7 characters' : 'Password contener tener 7 o más caracteres'),
      ],
      confirmPassword: '',
      confirmPasswordRules: [
        v => !!v || 'Password is required',
        v => (this.password === this.confirmPassword) || (this.selectedLang == 1 ? 'Passwords must match' : 'Las contraseñas deben coincidir')],
      select: null,
      items: [
        'Item 1',
        'Item 2',
        'Item 3',
        'Item 4',
      ],
      termsCheck: false,
      poliCheck: false,
      adsCheck: false,
      poliDialog: false,
      termsDialog:false,
      email: '',
      emailRules: [
        value => {
          if (value) return true

          return 'E-mail is requred.'
        },
        value => {
          if (/.+@.+\..+/.test(value)) return true

          return 'E-mail must be valid.'
        },
      ],
    }
  },
  methods: {

    async validate() {
      const { valid } = await this.$refs.form.validate()

      if (valid) alert('Form is valid')
    },
    poliOpen() {
      this.poliDialog = true;
    },
    termsOpen() {
      this.termsDialog = true;
    },
    reset() {
      this.$refs.form.reset()
    },
    clean() {
      this.code = '';
      this.name = '';
      this.password = '';
      this.confirmPassword = '';
      this.email = '';
      this.code = '';
    },
    closeDialog() {

      if (this.titleText == 'Success' || this.titleText == 'Exíto') {
        location.reload();
      }

      this.alert = false;
    },
    resetValidation() {
      this.$refs.form.reset();
    },
    redirectToPage() {
      this.$router.push('/Form');
    },
    async SendData() {
      try {
        const { valid } = await this.$refs.form.validate()

        if (valid) {

          const apiClient = axios.create({
            baseURL: 'https://petsocietyislandsupport.com:8443/api',
            headers: {
              'Content-Type': 'application/json'
            }
          });

          try {

            this.overlay = true;

            
            this.responseCodeTittle = '';
            this.responseCode = '';
            this.responseCodeAds = '';
            const dataToSend = { user: this.name, email: this.email, password: this.password, code: this.code,adsPerm: (this.adsCheck == true ? 1 : 0), lang: this.selectedLang };  // Los datos que quieres enviar

            const response = await apiClient.post('/PreRegister', dataToSend);

            var codeResponse = response.data;

            this.overlay = false;

            var objResponse = codeResponse.responseBD;

            if (objResponse.CodeRes == 1) {
              this.titleText = (this.selectedLang == 1 ? 'Atention': 'Atención');
              this.responseCodeTittle = (this.selectedLang == 1 ? 'The user and email address have already been used': 'El usuario y correo electronico ya han sido utilizados');
            }
            else if (objResponse.CodeRes == 2) {
              this.titleText = (this.selectedLang == 1 ? 'Atention': 'Atención');
              this.responseCodeTittle = (this.selectedLang == 1 ? 'The email address have already been used': 'El correo electronico ya ha sido utilizado');
            }
            else if (objResponse.CodeRes == 3) {
              this.titleText = (this.selectedLang == 1 ? 'Atention': 'Atención');
              this.responseCodeTittle =  (this.selectedLang == 1 ? 'The user have already been used': 'El usuario  ya ha sido utilizado');
            }
            else if (objResponse.CodeRes == 4) {
              this.titleText = (this.selectedLang == 1 ? 'Atention': 'Atención');
              this.responseCodeTittle =  (this.selectedLang == 1 ? 'An error ocurred': 'Ha ocurrido un error');
            }
            else {
              this.titleText = (this.selectedLang == 1 ? 'Success': 'Exíto');
              this.responseCodeTittle += (this.selectedLang == 1 ? 'User successfully registered \n': 'Usuario registrado con exito \n');
              this.responseCode += (this.selectedLang == 1 ? 'Your invitation code is:   \n' : 'Tu codigo de invitacion es:  \n') + objResponse.CodeRes.toUpperCase();
              this.responseCodeAds += (this.selectedLang == 1 ? 'Use your registered username and password to login on Pet Society Island \n' : 'Utiliza el usuario y contraseña registrados para iniciar sesion en Pet Society Island \n');
            }


            this.alert = true;

          } catch (error) {
            this.overlay = false;
          }


        }
        else {
        }




      } catch (error) {
      }
    },

  },
  async mounted() {
    //this.alert = true;
    document.title = "Pet Society Island";
  }
};
</script>
<style>

.footer {
  color: black !important;
}

.SquareCar {
  width: 50vh;
  height: 50vh;
}

.text-white input {
  color: black !important;

}

.sectionInfo {
  font-family: 'DeliCustom', sans-serif;
  /* Cambia el segundo valor por la fuente base si Delightious no está disponible */
  font-size: 25px;
  min-height: 100% !important;
  height: 40vh !important;
  border-width: 0px;
  width: 100%;
  justify-content: center !important;
  color: white;

}

.chatSectionEmpty {
  font-family: 'DeliCustom', sans-serif;
  margin-bottom: -100px;
  /* Cambia el segundo valor por la fuente base si Delightious no está disponible */
  font-size: 40px;
  min-height: 100% !important;
  height: 60vh !important;
  border-width: 0px;
  width: 100%;
  min-height: 40vh;
  justify-content: center !important;
}

.FormContainer {
  margin-top: 0%;
  /* justify-content: center !important; */
  /* max-width: 80%; */
  font-family: 'DeliCustom', sans-serif;
}

.gralContainer {
  /* height: -webkit-fill-available; */
}

.textForm {
  width: inherit;
  border-radius: 3%;
}

.position-relative {
  position: relative;
}

.top-right-select {
  position: absolute;
  top: 16px; /* Ajusta según tus necesidades */
  right: 16px; /* Ajusta según tus necesidades */
}

@media only screen and (min-width: 100px) {

  /* .textForm {
  width: inherit;
    width: 50%;
    height: 50%;
    border-radius: 3%;
  } */
}
</style>
